
























import {Component, Mixins} from "vue-property-decorator";
import XButton from "../SimpleButton.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: {
    XButton,
  },
})
class PasswordRecoveryConfirm extends Mixins(AppApiMixin) {
  error = "";
  success = "";

  public mounted() {
    this.error = "";
    this.success = "";
    const login = String(this.$route.query.login || "");
    const guid = String(this.$route.query.guid || "");
    this.recoveryPasswordConfirm(login, guid)
      .then((response) => {
        this.success = "Письмо с паролем отправлено на электронную почту " + response;
      })
      .catch((e) => {
        this.error = e;
      });
  }
  private openSignInPage(): void {
    this.$router.push({ name: "signIn" });
  }
}

export default PasswordRecoveryConfirm;
